var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Salary-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c("col2-block", { attrs: { title: "薪资信息" } }, [
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "员工编号",
                          prop: "jobNum",
                          rules: [
                            {
                              required: true,
                              validator: _vm.validateSalary,
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "v-select2",
                          _vm._b(
                            {
                              attrs: {
                                placeholder: "查询员工编号",
                                width: _vm.width,
                                disabled: _vm.edit,
                              },
                              on: { onChange: _vm.getBasicInfo },
                              model: {
                                value: _vm.form.jobNum,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "jobNum", $$v)
                                },
                                expression: "form.jobNum",
                              },
                            },
                            "v-select2",
                            _vm.numberParams,
                            false
                          )
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "员工姓名",
                        text: _vm.basicInfo.userName || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "证件类型",
                        text: _vm.basicInfo.cardType || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "证件号码",
                        text: _vm.basicInfo.cardNum || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "联系电话",
                        text: _vm.basicInfo.mobileNum || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "所属公司",
                        text: _vm.basicInfo.tenantName || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "部门",
                        text: _vm.basicInfo.orgName || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "岗位",
                        text: _vm.basicInfo.adminDuty || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "职级",
                        text: _vm.basicInfo.jobRank || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div salary-pad" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "薪资日期",
                          prop: "fmonth",
                          rules: [
                            {
                              required: true,
                              message: "请选择薪资日期",
                              trigger: "change",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-datepicker", {
                          attrs: {
                            type: "month",
                            width: _vm.width,
                            format: "YYYY-MM",
                          },
                          model: {
                            value: _vm.form.fmonth,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fmonth", $$v)
                            },
                            expression: "form.fmonth",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "salary-div" }),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "薪资档级",
                        text: _vm.basicInfo.salaryGrade || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "基本工资",
                        text: _vm.salaryStandardInfo.baseSalary || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "绩效工资",
                        text: _vm.salaryStandardInfo.performanceSalary || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "社会保险",
                        text: _vm.salaryStandardInfo.socialInsurance || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "社险补贴",
                        text:
                          _vm.salaryStandardInfo.socialInsuranceSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "公积金",
                        text: _vm.salaryStandardInfo.providentFund || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "公积金补贴",
                        text:
                          _vm.salaryStandardInfo.providentFundSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "通讯补贴",
                        text:
                          _vm.salaryStandardInfo.communicationSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "交通补贴",
                        text:
                          _vm.salaryStandardInfo.transportationSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "餐贴",
                        text: _vm.salaryStandardInfo.mealSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "汽油费补贴",
                        text: _vm.salaryStandardInfo.petrolSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "岗位补贴",
                        text: _vm.salaryStandardInfo.jobRankSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c("col2-item", {
                      attrs: {
                        label: "其他补贴",
                        text: _vm.salaryStandardInfo.otherSubsidy || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "考核奖",
                        text: _vm.salaryStandardInfo.assessmentAward || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                    _c("col2-item", {
                      attrs: {
                        label: "高温费",
                        text: _vm.salaryStandardInfo.highTemperatureFee || "--",
                        span: 8,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div salary-pad" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "加班费",
                          prop: "overtimeFee",
                          rules: [
                            {
                              required: true,
                              message: "请填写加班费",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "加班费",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.overtimeFee,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "overtimeFee", $$v)
                            },
                            expression: "form.overtimeFee",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "请假费",
                          prop: "leaveFee",
                          rules: [
                            {
                              required: true,
                              message: "请填写请假费",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "请假费",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.leaveFee,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "leaveFee", $$v)
                            },
                            expression: "form.leaveFee",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "summary-salary" }, [
                  _c("span", [_vm._v("合计：" + _vm._s(_vm.salaryTotal))]),
                ]),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "社保扣款",
                          prop: "socialSecurityDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写社保扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "社保扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.socialSecurityDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "socialSecurityDeduction", $$v)
                            },
                            expression: "form.socialSecurityDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "公积金扣款",
                          prop: "providentFundDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写公积金扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "公积金扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.providentFundDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "providentFundDeduction", $$v)
                            },
                            expression: "form.providentFundDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "养老扣款",
                          prop: "pensionDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写养老扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "养老扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.pensionDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pensionDeduction", $$v)
                            },
                            expression: "form.pensionDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "生育扣款",
                          prop: "maternityDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写生育扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "生育扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.maternityDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "maternityDeduction", $$v)
                            },
                            expression: "form.maternityDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "失业扣款",
                          prop: "unemploymentDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写失业扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "失业扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.unemploymentDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unemploymentDeduction", $$v)
                            },
                            expression: "form.unemploymentDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "工伤扣款",
                          prop: "workInjuryDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写工伤扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "工伤扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.workInjuryDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "workInjuryDeduction", $$v)
                            },
                            expression: "form.workInjuryDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "医疗扣款",
                          prop: "medicalDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写医疗扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "医疗扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.medicalDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "medicalDeduction", $$v)
                            },
                            expression: "form.medicalDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "个人抵扣税",
                          prop: "personalTaxDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写个人抵扣税",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "个人抵扣税",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.personalTaxDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "personalTaxDeduction", $$v)
                            },
                            expression: "form.personalTaxDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "个税",
                          prop: "personalTax",
                          rules: [
                            {
                              required: true,
                              message: "请填写个税",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "个税",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.personalTax,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "personalTax", $$v)
                            },
                            expression: "form.personalTax",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "salary-div" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "其他扣款",
                          prop: "otherDeduction",
                          rules: [
                            {
                              required: true,
                              message: "请填写其他扣款",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("v-input-number", {
                          attrs: {
                            placeholder: "其他扣款",
                            max: 99999999999999999999.99,
                            precision: 2,
                            min: 0,
                            width: _vm.width,
                          },
                          model: {
                            value: _vm.form.otherDeduction,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "otherDeduction", $$v)
                            },
                            expression: "form.otherDeduction",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "summary-salary" }, [
                  _c("span", [_vm._v("扣减：" + _vm._s(_vm.salaryDeduction))]),
                ]),
                _c("div", { staticClass: "summary-salary" }, [
                  _c("span", [_vm._v("实发：" + _vm._s(_vm.salaryActual))]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }