<template>
  <div class="Salary-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="薪资信息">
          <div class="salary-div">
            <el-form-item
              label="员工编号"
              prop="jobNum"
              :rules="[
                { required: true, validator: validateSalary, trigger: 'blur' },
              ]"
            >
              <v-select2
                placeholder="查询员工编号"
                v-model="form.jobNum"
                v-bind="numberParams"
                @onChange="getBasicInfo"
                :width="width"
                :disabled="edit"
              ></v-select2>
            </el-form-item>
          </div>
          <div class="salary-div">
            <col2-item
              label="员工姓名"
              :text="basicInfo.userName || '--'"
              :span="8"
              required
            />
            <col2-item
              label="证件类型"
              :text="basicInfo.cardType || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="证件号码"
              :text="basicInfo.cardNum || '--'"
              :span="8"
              required
            />
            <col2-item
              label="联系电话"
              :text="basicInfo.mobileNum || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="所属公司"
              :text="basicInfo.tenantName || '--'"
              :span="8"
              required
            />
            <col2-item
              label="部门"
              :text="basicInfo.orgName || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="岗位"
              :text="basicInfo.adminDuty || '--'"
              :span="8"
              required
            />
            <col2-item
              label="职级"
              :text="basicInfo.jobRank || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div salary-pad">
            <el-form-item
              label="薪资日期"
              prop="fmonth"
              :rules="[
                {
                  required: true,
                  message: '请选择薪资日期',
                  trigger: 'change',
                },
              ]"
            >
              <v-datepicker
                v-model="form.fmonth"
                type="month"
                :width="width"
                format="YYYY-MM"
              />
            </el-form-item>
          </div>
          <div class="salary-div">           
            <!-- <el-form-item
              label="薪资档级"
              prop="userName"
              :rules="[
                { required: true, validator: validateSalary, trigger: 'blur' },
              ]"
            >
              <v-select2
                placeholder="查询薪资档级"
                v-model="form.userName"
                v-bind="numberParams"
                @onChange="getBasicInfo"
                :width="width"
                :disabled="edit"
              ></v-select2>
            </el-form-item> -->
          </div>
          <div class="salary-div">
            <col2-item
              label="薪资档级"
              :text="basicInfo.salaryGrade || '--'"
              :span="8"
              required
            />
            <col2-item
              label="基本工资"
              :text="salaryStandardInfo.baseSalary || '--'"
              :span="8"
              required
            />
            <col2-item
              label="绩效工资"
              :text="salaryStandardInfo.performanceSalary || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="社会保险"
              :text="salaryStandardInfo.socialInsurance || '--'"
              :span="8"
              required
            />
            <col2-item
              label="社险补贴"
              :text="salaryStandardInfo.socialInsuranceSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="公积金"
              :text="salaryStandardInfo.providentFund || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="公积金补贴"
              :text="salaryStandardInfo.providentFundSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="通讯补贴"
              :text="salaryStandardInfo.communicationSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="交通补贴"
              :text="salaryStandardInfo.transportationSubsidy || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="餐贴"
              :text="salaryStandardInfo.mealSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="汽油费补贴"
              :text="salaryStandardInfo.petrolSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="岗位补贴"
              :text="salaryStandardInfo.jobRankSubsidy || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div">
            <col2-item
              label="其他补贴"
              :text="salaryStandardInfo.otherSubsidy || '--'"
              :span="8"
              required
            />
            <col2-item
              label="考核奖"
              :text="salaryStandardInfo.assessmentAward || '--'"
              :span="8"
              required
            />
            <col2-item
              label="高温费"
              :text="salaryStandardInfo.highTemperatureFee || '--'"
              :span="8"
              required
            />
          </div>
          <div class="salary-div salary-pad">
            <el-form-item
              label="加班费"
              prop="overtimeFee"
              :rules="[
                {
                  required: true,
                  message: '请填写加班费',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="加班费"
                v-model="form.overtimeFee"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="请假费"
              prop="leaveFee"
              :rules="[
                {
                  required: true,
                  message: '请填写请假费',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="请假费"
                v-model="form.leaveFee"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="summary-salary">
            <span>合计：{{ salaryTotal }}</span>
          </div>
          <div class="salary-div">
            <el-form-item
              label="社保扣款"
              prop="socialSecurityDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写社保扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="社保扣款"
                v-model="form.socialSecurityDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="公积金扣款"
              prop="providentFundDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写公积金扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="公积金扣款"
                v-model="form.providentFundDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="养老扣款"
              prop="pensionDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写养老扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="养老扣款"
                v-model="form.pensionDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="salary-div">
            <el-form-item
              label="生育扣款"
              prop="maternityDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写生育扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="生育扣款"
                v-model="form.maternityDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="失业扣款"
              prop="unemploymentDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写失业扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="失业扣款"
                v-model="form.unemploymentDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="工伤扣款"
              prop="workInjuryDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写工伤扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="工伤扣款"
                v-model="form.workInjuryDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="salary-div">
            <el-form-item
              label="医疗扣款"
              prop="medicalDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写医疗扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="医疗扣款"
                v-model="form.medicalDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="个人抵扣税"
              prop="personalTaxDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写个人抵扣税',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="个人抵扣税"
                v-model="form.personalTaxDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
            <el-form-item
              label="个税"
              prop="personalTax"
              :rules="[
                {
                  required: true,
                  message: '请填写个税',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="个税"
                v-model="form.personalTax"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="salary-div">
            <el-form-item
              label="其他扣款"
              prop="otherDeduction"
              :rules="[
                {
                  required: true,
                  message: '请填写其他扣款',
                  trigger: 'blur',
                },
              ]"
            >
              <v-input-number
                placeholder="其他扣款"
                v-model="form.otherDeduction"
                :max="99999999999999999999.99"
                :precision="2"
                :min="0"
                :width="width"
              />
            </el-form-item>
          </div>
          <div class="summary-salary">
            <span>扣减：{{ salaryDeduction }}</span>
          </div>
          <div class="summary-salary">
            <span>实发：{{ salaryActual }}</span>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  saveOutURL,
  updateOutURL,
  queryURL,
  getInitInfoURL,
  getBasicInfoURL,
  getSalaryStandardByTenantAndGradeURL
} from "./api";
import { Col2Detail, Col2Block, Col2Item } from "components/bussiness";
export default {
  name: "CommunicationForm",
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
  },
  data() {
    return {
      width: 182,
      form: {
        // id: "",
        enterpriseUserId: "",
        userId: "",
        jobNum: "",
        fmonth: "",
        overtimeFee: undefined,
        leaveFee: undefined,
        socialSecurityDeduction: undefined,
        providentFundDeduction: undefined,
        pensionDeduction: undefined,
        maternityDeduction: undefined,
        unemploymentDeduction: undefined,
        workInjuryDeduction: undefined,
        medicalDeduction: undefined,
        personalTaxDeduction: undefined,
        personalTax: undefined,
        otherDeduction: undefined,
        total: undefined,
        totalDeduction: undefined,
        actualPay: undefined
      },
      maritalStatusOps: [],
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: saveOutURL,
        //submitContentType: "application/x-www-form-urlencoded;charset=UTF-8",
      },
      edit: false,
      basicInfo: {}, //员工基础信息
      salaryStandardInfo: {},//薪资基础信息
      numberParams: {
        searchUrl: getBasicInfoURL,
        method: "get",
        request: {
          text: 'keyWord',
          value: 'keyWord'
        },
        response: {
          text: 'jobNum',
          value: 'jobNum'
        }
      },
    };
  },
  computed: {
    isEdit() {
      return this.$route.query.id;
    },
    salaryTotal() {
      return (
        (parseFloat(this.form.overtimeFee || 0) +        
        parseFloat(this.salaryStandardInfo.baseSalary || 0) +
        parseFloat(this.salaryStandardInfo.performanceSalary || 0) +
        parseFloat(this.salaryStandardInfo.socialInsurance || 0) +
        parseFloat(this.salaryStandardInfo.socialInsuranceSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.providentFund || 0) +
        parseFloat(this.salaryStandardInfo.providentFundSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.communicationSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.transportationSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.mealSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.petrolSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.jobRankSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.otherSubsidy || 0) +
        parseFloat(this.salaryStandardInfo.assessmentAward || 0) +
        parseFloat(this.salaryStandardInfo.highTemperatureFee || 0) -
        parseFloat(this.form.leaveFee || 0)).toFixed(2)
      )
    },
    salaryDeduction() {
      return (
        (parseFloat(this.form.socialSecurityDeduction || 0) +
        parseFloat(this.form.providentFundDeduction || 0) +
        parseFloat(this.form.pensionDeduction || 0) +
        parseFloat(this.form.maternityDeduction || 0) +
        parseFloat(this.form.unemploymentDeduction || 0) +
        parseFloat(this.form.workInjuryDeduction || 0) +
        parseFloat(this.form.medicalDeduction || 0) +        
        parseFloat(this.form.personalTax || 0) +
        parseFloat(this.form.otherDeduction || 0) -
        parseFloat(this.form.personalTaxDeduction || 0)).toFixed(2)
      )
    },
    salaryActual() {
      return (this.salaryTotal-this.salaryDeduction).toFixed(2)
    },
  },
  mounted() {
    const { id,edit } = this.$route.query;
    if (id !== undefined) {     
      if(edit){
        this.$setBreadcrumb("编辑");
        this.submitConfig.submitUrl = updateOutURL;
      } else{
        this.$setBreadcrumb("新增");
        this.submitConfig.submitUrl = saveOutURL
      }      
      this.$refs.formPanel.getData({ id });
      this.edit = true;
    } else {
      this.$setBreadcrumb("新增");
      this.submitConfig.submitUrl = saveOutURL;
    }
    // this.getSelectList();
  },
  methods: {
    getBasicInfo(data, echo) {
      console.log(data,'8888')
      this.basicInfo=data
      this.form.enterpriseUserId=data.enterpriseUserId
      this.form.userId=data.userId
      this.getBasicInfoList()
      // if (!echo) {
        
      //   console.log(data, echo);
      // }
    },
    getBasicInfoList() {
      // 获取用户基础信息
      let params = {
        tenantId: this.basicInfo.tenantId,
        salaryGrade: this.basicInfo.salaryGrade
      };
      this.$axios.get(getSalaryStandardByTenantAndGradeURL, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.salaryStandardInfo = res.data;
        }
      });
    },
    getSelectList() {
      // 其余下拉选项数据
      this.$axios.get(getInitInfoURL).then((res) => {
        if (res.status === 100 && res.data) {
          const { isMarryList } = res.data;
          this.maritalStatusOps = isMarryList.map((item) => ({
            text: item,
            value: item,
          }));
        }
      });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore(data) {
      if (this.$route.query.edit) {
        data.id = this.$route.query.id;
      }
      data.fmonth = data.fmonth + '-01'
      data.total=this.salaryTotal
      data.totalDeduction=this.salaryDeduction
      data.actualPay=this.salaryActual
      return true;
    },
    validateSalary(rule, value, callback) {
      const salaryRegular = /\d+|\d.\d+|0\.\d+/;
      if (value === "") {
        callback(new Error("该项不能为空"));
      } else if (!salaryRegular.test(value)) {
        callback(new Error("格式不正确，请输入数字"));
      } else {
        callback();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.Salary-wrapper {
  .salary-div {
    display: flex;
    // align-items: center;
  }
  .salary-pad {
    padding-top: 20px;
  }
  .summary-salary {
    text-align: right;
    padding-bottom: 20px;
  }
  .col2-container {
    width: 1262px !important;
  }
}
</style>

